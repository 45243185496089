@import "https://stackpath.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css";
@import url(../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css);
@import url(../node_modules/react-toastify/dist/ReactToastify.css);
html, body {
  height: 100%;
  scroll-behavior: smooth;
  font-family: 'Open Sans', sans-serif; }

body.guest-user {
  align-items: center;
  background: url("/images/bg_color.jpg") center;
  background-size: cover;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding: 0;
  margin: 0; }

table > thead > tr > th {
  white-space: nowrap; }

.circle:before {
  content: ' \25CF';
  font-size: x-large;
  white-space: nowrap; }

body {
  background-size: cover;
  min-height: 100vh;
  padding: 0;
  margin: 0; }

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none; }

#root {
  width: 100%; }

.title {
  color: #9C9C9C !important; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #eee; }

.first-row-with-header {
  padding-top: 8rem; }

.has-header-nogap {
  padding-top: 8rem; }

.row-no-padding [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.sortable {
  cursor: pointer; }

.btn, .dropdown-toggle.btn-xs {
  border-radius: 5rem; }

.btn-xs, .dropdown-toggle.btn-xs {
  padding: 2px; }

.primary-color-variation {
  color: #5ec7ce; }

.vertical-center {
  display: flex;
  align-items: center; }

label {
  margin-bottom: 0;
  margin-right: 0.5rem; }

.caret {
  margin: 3px; }

.form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 6px 12px;
  font-size: 14px;
  background-color: #fafafa;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 20px;
  color: #9C9C9C !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s; }

.btn-outline {
  background-color: transparent;
  color: inherit;
  transition: all .2s;
  border: 1px solid #fff; }

.btn-outline:hover {
  background-color: #fff;
  transition: all .2s;
  color: #000; }

#basic-nav-dropdown {
  line-height: 85px; }

.row.no-pad {
  margin-right: 0;
  margin-left: 0; }

.row.no-pad > [class*='col-'] {
  padding-right: 0;
  padding-left: 0; }

@media (min-width: 992px) {
  .modal-lg {
    width: 1090px; } }

html, body {
  height: 100%;
  scroll-behavior: smooth;
  font-family: 'Open Sans', sans-serif; }

body.guest-user {
  align-items: center;
  background: url("/images/bg_color.jpg") center;
  background-size: cover;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding: 0;
  margin: 0; }

table > thead > tr > th {
  white-space: nowrap; }

.circle:before {
  content: ' \25CF';
  font-size: x-large;
  white-space: nowrap; }

body {
  background-size: cover;
  min-height: 100vh;
  padding: 0;
  margin: 0; }

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none; }

#root {
  width: 100%; }

.title {
  color: #9C9C9C !important; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #eee; }

.first-row-with-header {
  padding-top: 8rem; }

.has-header-nogap {
  padding-top: 8rem; }

.row-no-padding [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.sortable {
  cursor: pointer; }

.btn, .dropdown-toggle.btn-xs {
  border-radius: 5rem; }

.btn-xs, .dropdown-toggle.btn-xs {
  padding: 2px; }

.primary-color-variation {
  color: #5ec7ce; }

.vertical-center {
  display: flex;
  align-items: center; }

label {
  margin-bottom: 0;
  margin-right: 0.5rem; }

.caret {
  margin: 3px; }

.form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 6px 12px;
  font-size: 14px;
  background-color: #fafafa;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 20px;
  color: #9C9C9C !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s; }

.btn-outline {
  background-color: transparent;
  color: inherit;
  transition: all .2s;
  border: 1px solid #fff; }

.btn-outline:hover {
  background-color: #fff;
  transition: all .2s;
  color: #000; }

#basic-nav-dropdown {
  line-height: 85px; }

.row.no-pad {
  margin-right: 0;
  margin-left: 0; }

.row.no-pad > [class*='col-'] {
  padding-right: 0;
  padding-left: 0; }

@media (min-width: 992px) {
  .modal-lg {
    width: 1090px; } }

html,
body {
  margin: 0; }

.logo {
  height: 100%; }

.selectDisable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none; }

/* Individual item */
.bm-item {
  display: inline-block;
  /* Our sidebar item styling */
  text-decoration: none;
  color: white;
  transition: color 0.2s; }

/* Change color on hover */
.bm-item:hover {
  color: white; }

/* The rest copied directly from react-burger-menu docs */
/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 32px;
  height: 28px;
  left: 28px;
  top: 35px;
  z-index: 1033 !important; }

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #4A5DA8; }

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px; }

.breadcrumb {
  color: #9C9C9C;
  margin-bottom: 5px !important; }

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7; }

/* General sidebar styles */
.bm-menu {
  background: #4A5DA8;
  font-size: 1.5rem; }

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #4A5DA8;
  color: #082341;
  opacity: 0.7; }

ul {
  padding: 0;
  list-style-type: none; }

.bm-menu-content {
  padding-top: 2rem; }

/* Wrapper for item list */
nav > div.bm-item.bm-menu-content li {
  list-style-type: none;
  line-height: 4.6rem;
  padding-left: 4rem;
  border-left: 0.5rem solid transparent; }
  nav > div.bm-item.bm-menu-content li:hover {
    background-color: #3b4d86;
    border-left: 0.5rem solid #5ec7ce;
    transition: all 0.2s; }
    nav > div.bm-item.bm-menu-content li:hover i {
      color: #5ec7ce; }

.activeMenuItem {
  background-color: #3b4d86;
  border-left: 0.5rem solid #5ec7ce;
  transition: all 0.2s; }
  .activeMenuItem i {
    color: #5ec7ce; }

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3); }

.navbar-nav > li > a {
  line-height: 75px;
  padding: 5px 15px 5px 25px; }

#basic-nav-dropdown {
  line-height: 86px; }

.carousel-status {
  display: none !important; }

.panel {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  -webkit-box-shadow: 0 29px 49px rgba(26, 64, 127, 0.35);
  box-shadow: 0 15px 49px rgba(26, 64, 127, 0.35);
  padding: 10px;
  margin-top: 10px; }

.panel-heading {
  padding: 10px 15px;
  border-bottom: 0px solid transparent;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; }

.has-error .form-control {
  border-color: #ED1C24; }

.file_upload_input {
  padding-right: 100px !important; }

.swal2-popup {
  font-size: inherit !important; }

.button-with-padding {
  margin: 5px;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem; }

.btn-default {
  color: #333;
  background-color: #E5E5E5;
  border-color: #ccc;
  border: 1px solid #9C9C9C;
  border-color: #9C9C9C !important; }

.btn-primary {
  background-color: #f7941d; }

.btn-invalid {
  background-color: #ED1C24; }

.btn-valid {
  background-color: #2DEF56; }

.btn {
  margin-right: 10px; }

.btn.active, .btn:active {
  -webkit-box-shadow: unset;
  box-shadow: unset; }

.btn-primary-color {
  color: #fff;
  background-color: #5ec7ce; }
  .btn-primary-color :active {
    color: #fff; }

.btn-info {
  background-color: #5ec7ce !important; }

.btn-primary-color-disabled {
  color: #b4b3b3 !important;
  background-color: #f0f0f0; }

.btn-secondary {
  color: #fff;
  background-color: #9C9C9C; }

.btn-secondary:hover {
  color: #fff;
  background-color: #34a4ab; }

.swal-header {
  border-radius: 10px !important; }

.btn, .btn.active, .btn:hover {
  border-color: transparent; }

.reset-google-btn {
  display: unset;
  background: unset;
  color: unset;
  width: unset;
  padding-top: unset;
  padding-bottom: unset;
  border-radius: unset;
  border: unset;
  font-size: unset;
  font-weight: unset;
  font-family: unset; }

.facebook {
  background-color: #4367b0;
  border-color: #4367b0;
  color: #fff; }

.form .alert {
  display: block !important; }

.alert {
  margin: 1rem; }

.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 20px;
  list-style: none;
  background-color: transparent;
  border-radius: 4px;
  padding-inline-start: 0; }
  .breadcrumb li.active {
    color: #5ec7ce;
    font-weight: 800; }

.label__dark_blue {
  color: #082342;
  text-transform: uppercase; }

.form label {
  white-space: nowrap;
  text-transform: uppercase;
  display: -webkit-flex;
  margin-bottom: 8px;
  font-size: 12px; }

.bluevalues {
  font-weight: bold;
  font-size: 2rem;
  color: #5ec7ce; }

.form .form-group {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.react-bootstrap-table table {
  table-layout: auto !important; }

.form .form-group select {
  padding-right: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(/images/select_arrow.png) no-repeat 95%; }

.form .form-group select[disabled] {
  background: #eee; }

.label__grey {
  color: #9C9C9C !important;
  display: inherit; }

.label__greysmall {
  color: #9C9C9C !important;
  display: flex;
  text-transform: unset;
  letter-spacing: 0;
  margin-bottom: 0; }

.modal-title {
  margin-right: 40px; }

.dropzone .dz-preview .dz-error-message {
  top: 10px !important;
  color: white !important; }

.kpi .kpi-title {
  white-space: nowrap;
  text-align: left;
  color: #9C9C9C !important; }

.kpi .subtitle {
  text-align: center; }

button {
  outline: none; }

button.close {
  outline: none;
  -webkit-appearance: none;
  cursor: pointer;
  background: unset;
  border: 0;
  padding: unset; }

.form-control__grey {
  background-color: #fff;
  color: #9C9C9C; }

.filter-title {
  color: #9C9C9C;
  font-size: 12px;
  padding-right: 1rem;
  font-weight: bold;
  text-align: end; }

.form-control__white {
  background-color: #fff;
  border: 1px solid #fff; }

.form-control.sm {
  font-size: 12px;
  height: 30px; }

.radio-group {
  display: flex;
  height: 40px; }

.radio-option {
  color: #9C9C9C !important;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0 !important;
  margin: 0 30px 0 0;
  text-transform: capitalize !important; }
  .radio-option input {
    margin-right: 10px; }

.field-error {
  color: #ED1C24;
  font-size: 12px;
  text-align: left !important; }

.field-message {
  font-size: 12px;
  text-align: left !important; }

.form-note {
  color: #9C9C9C;
  font-size: 9px;
  margin-top: 35px;
  margin-left: -15px; }
  .form-note a {
    color: #9C9C9C;
    text-decoration: underline; }
  @media (max-width: 767px) {
    .form-note {
      margin-top: -5px;
      margin-left: 0; } }

.col {
  display: inline-grid; }

button:focus {
  outline: 0 !important; }

.box {
  transition: box-shadow .3s;
  padding: 2rem;
  border-radius: 10px;
  border: 1px solid #ccc;
  background: #fff;
  float: left;
  width: 100%;
  cursor: pointer; }

.box:hover, .box:active {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  color: #5ec7ce; }

#basic-nav-dropdown {
  color: #9c9c9c !important;
  border-bottom: 3px solid transparent; }
  #basic-nav-dropdown:hover {
    border-bottom: 3px solid #5ec7ce;
    transition: all 0.2s;
    color: #5ec7ce !important; }

.no-padding {
  padding: 0; }

.padding-top-5 {
  padding-top: 5px; }

.padding-top-10 {
  padding-top: 10px; }

.padding-top-15 {
  padding-top: 25px; }

.padding-top-20 {
  padding-top: 20px; }

.padding-top-25 {
  padding-top: 25px; }

.padding-top-30 {
  padding-top: 30px; }

.padding-top-35 {
  padding-top: 35px; }

.padding-top-40 {
  padding-top: 40px; }

.padding-top-45 {
  padding-top: 45px; }

.padding-top-50 {
  padding-top: 50px; }

.padding-top-55 {
  padding-top: 55px; }

.padding-top-60 {
  padding-top: 60px; }

.w-100 {
  width: 100% !important; }

.form-modal .btn-primary__cancel {
  background-color: #fff !important;
  border: 0;
  box-shadow: none;
  color: #9C9C9C;
  font-weight: 600;
  letter-spacing: 5px;
  margin-right: 30px;
  padding: 10px 0;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .form-modal .btn-primary__cancel {
      margin-top: 10px; } }
  .form-modal .btn-primary__cancel:hover {
    background-color: #fff; }

.form-modal .btn_primary__confirm {
  font-weight: 600;
  letter-spacing: 5px;
  margin-bottom: 50px;
  padding: 10px 20px;
  min-width: 160px;
  text-transform: uppercase; }
  .form-modal .btn_primary__confirm:disabled {
    background-color: #fff; }
  @media (max-width: 767px) {
    .form-modal .btn_primary__confirm {
      margin-top: 10px; } }

.login-area {
  color: #9C9C9C;
  font-size: 14px;
  padding: 15px; }
  .login-area a {
    color: #9C9C9C;
    text-decoration: underline; }
  .login-area p {
    text-align: center; }
  .login-area .text_small {
    font-size: 12px; }

.btn-primary__facebook {
  border-radius: 2em;
  background-color: #4367b0;
  border-color: #4367b0;
  color: #fff;
  font-size: 11px;
  padding: 10px 0;
  width: 93%; }
  @media (max-width: 992px) {
    .btn-primary__facebook {
      width: 100%; } }
  .btn-primary__facebook:hover {
    background-color: #3a5998;
    color: #fff; }

.btn-primary__google {
  border-radius: 2em;
  border: 1px solid #4367b0;
  color: #9C9C9C;
  font-size: 11px;
  padding: 10px 0;
  width: 93%; }
  @media (max-width: 992px) {
    .btn-primary__google {
      margin-top: 10px;
      width: 100%; } }

.btn-primary__login {
  font-weight: 600;
  letter-spacing: 5px;
  padding: 10px 50px; }

.panel-body__login {
  min-height: 435px; }

.background-line {
  position: relative;
  z-index: 1; }
  .background-line :before {
    border-top: 1px solid #eeeeee;
    content: "";
    margin: 0 auto;
    /* this centers the line to the full width specified */
    position: absolute;
    /* positioning must be absolute here, and relative positioning must be applied to the parent */
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: -1; }
  .background-line span {
    /* to hide the lines from behind the text, you have to set the background color the same as the container */
    background: #fff;
    padding: 0 15px;
    font-weight: bold; }

.header {
  background-color: #ffffff;
  position: inherit; }

.navbar {
  height: 100px; }
  @media (max-width: 760px) {
    .navbar {
      height: 55px; } }

/* enable absolute positioning */
.inner-addon {
  position: relative;
  float: right;
  z-index: 1; }

.inner-addon .glyphicon {
  position: absolute;
  padding: 7px;
  font-size: 1em;
  pointer-events: none;
  color: #9C9C9C; }
  .inner-addon .glyphicon.glyphicon-remove {
    cursor: pointer;
    pointer-events: all; }

/* align glyph */
.left-addon .glyphicon {
  left: 5px; }

.right-addon .glyphicon {
  right: 5px; }

/* add padding  */
.left-addon input {
  padding-left: 30px;
  height: 28px; }

.right-addon input {
  padding-right: 30px;
  height: 28px; }

html, body {
  height: 100%;
  scroll-behavior: smooth;
  font-family: 'Open Sans', sans-serif; }

body.guest-user {
  align-items: center;
  background: url("/images/bg_color.jpg") center;
  background-size: cover;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding: 0;
  margin: 0; }

table > thead > tr > th {
  white-space: nowrap; }

.circle:before {
  content: ' \25CF';
  font-size: x-large;
  white-space: nowrap; }

body {
  background-size: cover;
  min-height: 100vh;
  padding: 0;
  margin: 0; }

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none; }

#root {
  width: 100%; }

.title {
  color: #9C9C9C !important; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #eee; }

.first-row-with-header {
  padding-top: 8rem; }

.has-header-nogap {
  padding-top: 8rem; }

.row-no-padding [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.sortable {
  cursor: pointer; }

.btn, .dropdown-toggle.btn-xs {
  border-radius: 5rem; }

.btn-xs, .dropdown-toggle.btn-xs {
  padding: 2px; }

.primary-color-variation {
  color: #5ec7ce; }

.vertical-center {
  display: flex;
  align-items: center; }

label {
  margin-bottom: 0;
  margin-right: 0.5rem; }

.caret {
  margin: 3px; }

.form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 6px 12px;
  font-size: 14px;
  background-color: #fafafa;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 20px;
  color: #9C9C9C !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s; }

.btn-outline {
  background-color: transparent;
  color: inherit;
  transition: all .2s;
  border: 1px solid #fff; }

.btn-outline:hover {
  background-color: #fff;
  transition: all .2s;
  color: #000; }

#basic-nav-dropdown {
  line-height: 85px; }

.row.no-pad {
  margin-right: 0;
  margin-left: 0; }

.row.no-pad > [class*='col-'] {
  padding-right: 0;
  padding-left: 0; }

@media (min-width: 992px) {
  .modal-lg {
    width: 1090px; } }

.logo-wrapper {
  background: blue;
  height: 100px;
  position: relative;
  background-color: #082341;
  text-align: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  @media (max-width: 760px) {
    .logo-wrapper {
      height: 55px; } }

.logo-wrapper img {
  height: 45px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.sidecontent {
  width: 300px;
  background-image: linear-gradient(to right, #4A5DA8, #4A5DA8);
  color: white; }
  .sidecontent ul {
    margin-top: 2rem; }
  .sidecontent ul > li {
    line-height: 4.6rem;
    padding-left: 4rem;
    border-left: 0.5rem solid transparent; }
    .sidecontent ul > li:hover {
      background-color: #334374;
      border-left: 0.5rem solid #5ec7ce;
      transition: all 0.5s; }
      .sidecontent ul > li:hover i {
        color: #5ec7ce;
        transition: all 0.5s; }
    .sidecontent ul > li.active {
      background-color: #334374;
      border-left: 0.5rem solid #5ec7ce;
      transition: all 1s; }

.property-register-steps {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  -webkit-box-shadow: 0 29px 49px rgba(26, 64, 127, 0.35);
  box-shadow: 0 29px 49px rgba(26, 64, 127, 0.35);
  position: fixed;
  padding: 10px;
  width: 95px;
  margin-top: 10px; }
  .property-register-steps .step {
    align-items: center;
    border: none;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin: 0 0 10px 0; }
    .property-register-steps .step:focus, .property-register-steps .step:hover {
      background-color: #fff;
      outline: none; }
    .property-register-steps .step:last-of-type {
      border: 0;
      margin-bottom: -5px; }
    .property-register-steps .step p {
      color: #9C9C9C;
      font-size: 10px;
      margin: 5px 0;
      text-align: center; }
      .property-register-steps .step p.completed {
        color: #2DEF56; }
      .property-register-steps .step p.invalid {
        color: #ED1C24; }

html, body {
  height: 100%;
  scroll-behavior: smooth;
  font-family: 'Open Sans', sans-serif; }

body.guest-user {
  align-items: center;
  background: url("/images/bg_color.jpg") center;
  background-size: cover;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding: 0;
  margin: 0; }

table > thead > tr > th {
  white-space: nowrap; }

.circle:before {
  content: ' \25CF';
  font-size: x-large;
  white-space: nowrap; }

body {
  background-size: cover;
  min-height: 100vh;
  padding: 0;
  margin: 0; }

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none; }

#root {
  width: 100%; }

.title {
  color: #9C9C9C !important; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #eee; }

.first-row-with-header {
  padding-top: 8rem; }

.has-header-nogap {
  padding-top: 8rem; }

.row-no-padding [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.sortable {
  cursor: pointer; }

.btn, .dropdown-toggle.btn-xs {
  border-radius: 5rem; }

.btn-xs, .dropdown-toggle.btn-xs {
  padding: 2px; }

.primary-color-variation {
  color: #5ec7ce; }

.vertical-center {
  display: flex;
  align-items: center; }

label {
  margin-bottom: 0;
  margin-right: 0.5rem; }

.caret {
  margin: 3px; }

.form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 6px 12px;
  font-size: 14px;
  background-color: #fafafa;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 20px;
  color: #9C9C9C !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s; }

.btn-outline {
  background-color: transparent;
  color: inherit;
  transition: all .2s;
  border: 1px solid #fff; }

.btn-outline:hover {
  background-color: #fff;
  transition: all .2s;
  color: #000; }

#basic-nav-dropdown {
  line-height: 85px; }

.row.no-pad {
  margin-right: 0;
  margin-left: 0; }

.row.no-pad > [class*='col-'] {
  padding-right: 0;
  padding-left: 0; }

@media (min-width: 992px) {
  .modal-lg {
    width: 1090px; } }

.property-register-page .main-row {
  margin: 0; }

.property-register-page .steps-column {
  padding-left: 0; }

.property-register-page .form-column {
  padding-left: 0;
  padding-right: 0; }
  @media (min-width: 992px) {
    .property-register-page .form-column {
      float: right;
      width: 88.3%; } }

.property-register-page .panel__property-register {
  padding: 0;
  overflow: hidden;
  margin-bottom: 30px; }

.property-register-page .panel-heading__property-register {
  background-color: #fff;
  border: 0;
  padding: 0; }

.property-register-page .panel-title__property-register {
  border-left: 10px solid;
  border-left-color: #9C9C9C;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #9C9C9C;
  cursor: pointer;
  display: block;
  font-size: 20px;
  padding: 18px 30px 18px 20px; }
  .property-register-page .panel-title__property-register .panel-arrow {
    float: right;
    margin-top: 5px;
    transition-duration: 0.2s; }
  .property-register-page .panel-title__property-register.open {
    border-left-color: #5ec7ce;
    color: #5ec7ce; }
    .property-register-page .panel-title__property-register.open .panel-arrow {
      transform: rotate(180deg); }
  .property-register-page .panel-title__property-register.completed {
    border-left-color: #2def56; }
  .property-register-page .panel-title__property-register.invalid {
    border-left-color: #ED1C24; }

.property-register-page .panel-body__property-register {
  border-top: 1px solid #ddd;
  margin: 0 30px;
  padding: 20px 0; }

.property-register-page .btn-primary__back {
  background-color: #fff !important;
  border: 0;
  box-shadow: none;
  color: #9C9C9C;
  font-weight: 600;
  letter-spacing: 5px;
  margin-right: 30px;
  padding: 10px 0; }
  @media (max-width: 767px) {
    .property-register-page .btn-primary__back {
      margin-top: 10px; } }
  .property-register-page .btn-primary__back:hover {
    background-color: #fff; }

.property-register-page .btn_primary__next {
  font-weight: 600;
  letter-spacing: 5px;
  padding: 10px 0;
  width: 160px; }
  .property-register-page .btn_primary__next:disabled {
    background-color: #f0f0f0; }
  @media (max-width: 767px) {
    .property-register-page .btn_primary__next {
      margin-top: 10px; } }

.property-register-page .btn_primary__register {
  font-weight: 600;
  letter-spacing: 5px;
  margin-bottom: 50px;
  padding: 10px 20px;
  min-width: 160px; }
  .property-register-page .btn_primary__register:disabled {
    background-color: #f0f0f0; }
  @media (max-width: 767px) {
    .property-register-page .btn_primary__register {
      margin-top: 10px; } }

.property-register-page .panel-footer__property-register {
  background-color: #fff;
  border: 0;
  margin-top: -10px;
  padding: 0px 30px 30px 30px; }

.contract-panel-expenses small {
  font-size: 11px;
  font-weight: normal;
  letter-spacing: 0;
  margin-left: 10px;
  text-transform: none; }

.contract-panel-expenses .btn-expense {
  border: 0;
  font-weight: 100;
  height: 40px;
  min-width: 91px;
  padding: 0 15px; }
  .contract-panel-expenses .btn-expense.btn-add-expense {
    background-color: #9C9C9C;
    color: #fff; }
  .contract-panel-expenses .btn-expense.btn-remove-expense {
    background-color: #fff;
    color: #9C9C9C; }
    .contract-panel-expenses .btn-expense.btn-remove-expense:hover {
      background-color: #fff;
      box-shadow: none; }
    @media (max-width: 767px) {
      .contract-panel-expenses .btn-expense.btn-remove-expense {
        margin-top: -15px;
        margin-bottom: 20px; } }

.contract-notes {
  border-radius: 10px;
  border: 1px solid #f0f0f0;
  min-height: 100px;
  width: 100%; }

.listing-description {
  border-radius: 10px;
  border: 1px solid #f0f0f0;
  min-height: 100px;
  width: 100%; }

.tenant-panel .text_small,
.owner-panel .text_small {
  color: #9C9C9C;
  font-size: 12px;
  padding-left: 15px; }
  .tenant-panel .text_small span,
  .owner-panel .text_small span {
    text-decoration: underline;
    cursor: pointer; }

.tenant-panel .account-selector-button,
.owner-panel .account-selector-button {
  background-color: #f0f0f0;
  color: #9C9C9C;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 10px;
  padding: 10px 0;
  width: 300px; }
  .tenant-panel .account-selector-button:hover,
  .owner-panel .account-selector-button:hover {
    background-color: #5ec7ce;
    box-shadow: none;
    color: #fff; }

.tenant-panel .btn-primary__person-type,
.owner-panel .btn-primary__person-type {
  background-color: #f0f0f0;
  color: #9C9C9C;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 10px;
  padding: 10px 0;
  width: 160px; }
  .tenant-panel .btn-primary__person-type.active, .tenant-panel .btn-primary__person-type:hover,
  .owner-panel .btn-primary__person-type.active,
  .owner-panel .btn-primary__person-type:hover {
    background-color: #5ec7ce;
    box-shadow: none;
    color: #fff; }
  .tenant-panel .btn-primary__person-type:nth-of-type(2),
  .owner-panel .btn-primary__person-type:nth-of-type(2) {
    margin-left: 30px; }
  @media (max-width: 767px) {
    .tenant-panel .btn-primary__person-type,
    .owner-panel .btn-primary__person-type {
      margin: 0 0 10px 0 !important;
      width: 100%; } }

div.panelFloat {
  position: -webkit-sticky;
  position: sticky;
  top: 90px; }

.grow {
  transition: all .2s ease-in-out; }

.grow:hover {
  transform: scale(1.05);
  cursor: pointer;
  box-shadow: 0 15px 49px rgba(26, 64, 127, 0.35); }

.btn {
  font-weight: normal; }

.navbar {
  margin-bottom: 0; }

.guarantee {
  text-align: start;
  border-radius: 0.5rem;
  padding: 2rem 1rem 1rem 1rem;
  border: 1px solid #D3D3D3; }

.property-listing-card {
  margin-top: 2.2rem;
  -webkit-font-smoothing: antialiased;
  border-radius: 0.5rem; }

.property-feature-item {
  color: #5ec7ce;
  line-height: 1.4; }

.property-feature-item span {
  font-size: 2rem;
  display: block; }

.titles {
  text-transform: uppercase;
  letter-spacing: .2em; }

.btn-sparse {
  text-transform: uppercase;
  letter-spacing: 5px;
  padding: 10px 20px; }

.titles span {
  text-transform: unset;
  letter-spacing: unset; }

.carousel .slide .legend {
  display: none; }

.img-valign {
  vertical-align: middle;
  margin-bottom: 0.2em; }

.col-carousel .slide div {
  cursor: pointer;
  margin-right: 3px; }

.carousel .slide {
  background-color: white !important; }

.carousel {
  background-color: white; }

.bold {
  font-weight: bold; }

.property-listing-card .title {
  font-size: 1.85rem;
  font-weight: bold; }

.property-listing-card .body {
  padding: 10px; }

.listing-line {
  margin-bottom: 5rem; }

.listing-line-content {
  margin-bottom: 3rem; }

.listing-line-content .title {
  padding-bottom: 2rem; }

.listing-line-content .description {
  text-align: justify;
  line-height: 1.5; }

.footer {
  -webkit-box-shadow: 0 8px 6px 8px #9C9C9C;
  -moz-box-shadow: 0 8px 6px 8px #9C9C9C;
  box-shadow: 0 8px 6px 8px #9C9C9C;
  padding: 4px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  text-align: center; }

.footer .amount {
  font-size: 2.8rem;
  line-height: 1; }

.listing-img {
  object-fit: cover;
  height: 300px; }

.small-listing-img {
  object-fit: cover;
  height: 150px !important; }

.floatingContainer {
  background-color: red;
  display: flex; }

.sticky {
  position: sticky;
  top: 90px; }

.tall {
  display: flex; }

.garantees span {
  display: block;
  text-align: left; }

.signup .main-row {
  display: flex;
  justify-content: center; }

.signup .panel {
  padding: 30px; }

.signup .panel-heading {
  background-color: #fff;
  margin-bottom: 15px;
  padding: 0;
  padding-bottom: 30px; }
  .signup .panel-heading h1 {
    color: #5ec7ce;
    font-size: 22px;
    margin: 0; }
  .signup .panel-heading .btn-primary__person-type {
    background-color: #f0f0f0;
    color: #9C9C9C;
    font-size: 12px;
    font-weight: 600;
    padding: 10px 0;
    width: 160px; }
    .signup .panel-heading .btn-primary__person-type.active, .signup .panel-heading .btn-primary__person-type:hover {
      background-color: #5ec7ce;
      box-shadow: none;
      color: #fff; }
    .signup .panel-heading .btn-primary__person-type:first-of-type {
      margin-left: 30px; }
    @media (max-width: 767px) {
      .signup .panel-heading .btn-primary__person-type {
        margin-top: 10px;
        width: 100%; } }

.signup .panel-body .profile-picture-wrapper {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 50%;
  margin: auto; }
  @media (min-width: 1200px) {
    .signup .panel-body .profile-picture-wrapper {
      height: 120px;
      width: 120px; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .signup .panel-body .profile-picture-wrapper {
      height: 100px;
      width: 100px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .signup .panel-body .profile-picture-wrapper {
      height: 60px;
      width: 60px; } }
  @media (max-width: 767px) {
    .signup .panel-body .profile-picture-wrapper {
      height: 120px;
      width: 120px; } }

.signup .panel-body .add_profile_picture_action {
  color: #9C9C9C;
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;
  margin: 20px 0 30px 0; }

.signup .panel-body h1 {
  color: #9C9C9C;
  font-size: 20px;
  margin: 0;
  margin-bottom: 20px; }

.signup .panel-body .btn-primary__back {
  background-color: #fff;
  color: #9C9C9C;
  font-weight: 600;
  letter-spacing: 5px;
  padding: 10px 0; }
  @media (max-width: 767px) {
    .signup .panel-body .btn-primary__back {
      margin-top: 10px;
      width: 100%; } }

.signup .panel-body .btn_primary__signup {
  font-weight: 600;
  letter-spacing: 5px;
  padding: 10px 20px;
  min-width: 160px; }
  @media (max-width: 767px) {
    .signup .panel-body .btn_primary__signup {
      margin-top: 10px;
      width: 100%; } }

.file-upload-field small {
  font-size: 11px;
  font-weight: normal;
  letter-spacing: 0;
  margin-left: 10px;
  text-transform: none; }

.file-upload-field .form-control[disabled] {
  background-color: #fff !important; }

.file-upload-field .file-upload-column {
  padding: 0; }
  @media (max-width: 767px) {
    .file-upload-field .file-upload-column.file-upload-btn-column {
      margin-top: -10px;
      margin-bottom: 20px; } }

.file-upload-field .file-upload-btn {
  border: 0;
  color: #fff;
  font-weight: 100;
  height: 40px;
  min-width: 91px; }
  .file-upload-field .file-upload-btn.file-upload-btn-select-file {
    position: absolute;
    top: 10px;
    right: -8px;
    background-color: #9C9C9C;
    padding: 0 14px; }
  .file-upload-field .file-upload-btn.file-upload-btn-send-file {
    background-color: #5ec7ce;
    position: absolute;
    top: 1px;
    right: -3px; }
    .file-upload-field .file-upload-btn.file-upload-btn-send-file.sent {
      background-color: #2DEF56; }
  .file-upload-field .file-upload-btn.file-upload-btn-delete-file {
    background-color: #fff;
    color: #9C9C9C; }
    .file-upload-field .file-upload-btn.file-upload-btn-delete-file:hover {
      background-color: #fff;
      box-shadow: none; }
  .file-upload-field .file-upload-btn.file-upload-btn-custom {
    background-color: #9C9C9C;
    padding: 0 15px; }
    @media (max-width: 767px) {
      .file-upload-field .file-upload-btn.file-upload-btn-custom {
        margin-top: 10px; } }

html, body {
  height: 100%;
  scroll-behavior: smooth;
  font-family: 'Open Sans', sans-serif; }

body.guest-user {
  align-items: center;
  background: url("/images/bg_color.jpg") center;
  background-size: cover;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding: 0;
  margin: 0; }

table > thead > tr > th {
  white-space: nowrap; }

.circle:before {
  content: ' \25CF';
  font-size: x-large;
  white-space: nowrap; }

body {
  background-size: cover;
  min-height: 100vh;
  padding: 0;
  margin: 0; }

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none; }

#root {
  width: 100%; }

.title {
  color: #9C9C9C !important; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #eee; }

.first-row-with-header {
  padding-top: 8rem; }

.has-header-nogap {
  padding-top: 8rem; }

.row-no-padding [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.sortable {
  cursor: pointer; }

.btn, .dropdown-toggle.btn-xs {
  border-radius: 5rem; }

.btn-xs, .dropdown-toggle.btn-xs {
  padding: 2px; }

.primary-color-variation {
  color: #5ec7ce; }

.vertical-center {
  display: flex;
  align-items: center; }

label {
  margin-bottom: 0;
  margin-right: 0.5rem; }

.caret {
  margin: 3px; }

.form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 6px 12px;
  font-size: 14px;
  background-color: #fafafa;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 20px;
  color: #9C9C9C !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s; }

.btn-outline {
  background-color: transparent;
  color: inherit;
  transition: all .2s;
  border: 1px solid #fff; }

.btn-outline:hover {
  background-color: #fff;
  transition: all .2s;
  color: #000; }

#basic-nav-dropdown {
  line-height: 85px; }

.row.no-pad {
  margin-right: 0;
  margin-left: 0; }

.row.no-pad > [class*='col-'] {
  padding-right: 0;
  padding-left: 0; }

@media (min-width: 992px) {
  .modal-lg {
    width: 1090px; } }

#stats_table > tbody > tr:nth-child(n) > td:nth-child(1) {
  text-align: left;
  white-space: nowrap; }

.question-mark-icon img {
  margin-left: 0.5rem;
  position: relative;
  width: 1.4rem;
  height: auto;
  top: -1px; }

.title .header-icon {
  float: right;
  color: white;
  vertical-align: middle;
  display: grid;
  font-size: 10px; }

.title .header-icon span {
  opacity: 0.6;
  display: table-cell;
  vertical-align: middle;
  background-color: lightgray;
  padding: 5px;
  border-radius: 50%; }

.title .header-icon span:hover {
  opacity: 1;
  border-radius: 50%;
  background-color: #5ec7ce !important; }

.listing-summary {
  float: none !important;
  margin-bottom: 1rem;
  color: #9C9C9C;
  white-space: nowrap; }
  .listing-summary label {
    letter-spacing: 0px;
    text-transform: none; }

.card-text-color {
  color: #9C9C9C; }

.grad {
  background-image: linear-gradient(#4b5daa, #61c7cf);
  color: #fff; }

.panel-heading {
  background-color: #fff !important;
  padding-bottom: 0;
  padding: 0px; }

.panel-heading-without-bottom-border {
  background-color: #fff !important;
  padding-bottom: 0;
  padding: 0px;
  border: 0; }

.toogle-header {
  cursor: pointer; }

h4 {
  position: relative; }

.btn-default-toggle {
  background-color: #DDDDDD; }

h4 span {
  position: absolute;
  bottom: 0;
  right: 0;
  vertical-align: bottom; }

.property-card {
  padding: 1rem; }

#menu {
  position: sticky;
  margin-bottom: -9999px;
  padding-bottom: 9999px;
  overflow: auto;
  padding-top: 20px;
  /* Place content 60px from the top */
  transition: 0.5s;
  /* 0.5 second transition effect to slide in the sidenav */ }
  @media (max-width: 320px) {
    #menu {
      padding-top: 10px; } }
  @media (max-width: 768px) {
    #menu {
      padding-top: 15px; } }

p {
  color: #9C9C9C; }

a, a:hover, a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s; }

.dot.red {
  color: red; }

.dot.green {
  color: limegreen; }

.dot.orange {
  color: orange; }

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
  vertical-align: middle; }

.content-table {
  color: #9C9C9C; }

.stats-content-table-rows {
  color: #9C9C9C;
  font-size: 1.3rem; }

.stats-content-table-header {
  color: #9C9C9C;
  font-weight: normal; }

#stats_table > thead > tr > th:not(:first-child) {
  text-align: center; }

.content-table a {
  font-size: 1.5rem;
  font-weight: bold; }

.boletoform {
  color: #9C9C9C; }

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
  border: 0; }

.glyphicon.fast-right-spinner {
  -webkit-animation: glyphicon-spin-r 1s infinite linear;
  animation: glyphicon-spin-r 1s infinite linear; }

.glyphicon.normal-right-spinner {
  -webkit-animation: glyphicon-spin-r 2s infinite linear;
  animation: glyphicon-spin-r 2s infinite linear; }

.glyphicon.slow-right-spinner {
  -webkit-animation: glyphicon-spin-r 3s infinite linear;
  animation: glyphicon-spin-r 3s infinite linear; }

.glyphicon.fast-left-spinner {
  -webkit-animation: glyphicon-spin-l 1s infinite linear;
  animation: glyphicon-spin-l 1s infinite linear; }

.glyphicon.normal-left-spinner {
  -webkit-animation: glyphicon-spin-l 2s infinite linear;
  animation: glyphicon-spin-l 2s infinite linear; }

.glyphicon.slow-left-spinner {
  -webkit-animation: glyphicon-spin-l 3s infinite linear;
  animation: glyphicon-spin-l 3s infinite linear; }

@-webkit-keyframes glyphicon-spin-r {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes glyphicon-spin-r {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@-webkit-keyframes glyphicon-spin-l {
  0% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); } }

@keyframes glyphicon-spin-l {
  0% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); } }

.property-list .title {
  margin-bottom: 30px; }

.property-list table th {
  background-color: #6aabb3;
  color: #fff;
  font-size: 16px;
  text-align: center; }

.property-list table tr {
  background-color: #fff; }
  .property-list table tr:hover {
    background-color: #7bc5ce;
    color: #fff;
    cursor: pointer; }
  .property-list table tr td {
    vertical-align: middle !important; }

.checkbox-group-option {
  margin: 0; }
  .checkbox-group-option label {
    color: #9C9C9C !important; }

#images_attributes_validator {
  display: none; }

.photos-dropzone .dz-details .dz-size {
  display: none; }

.photos-dropzone .dz-image img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  position: relative;
  top: 50%;
  transform: translateY(-50%); }

.photos-dropzone .dropzone .dz-preview:hover .dz-image img {
  transform: translateY(-50%) scale(1, 1) !important;
  filter: none !important; }

.photos-dropzone .filepicker {
  background-color: #fff !important; }
  .photos-dropzone .filepicker .gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80); }
  .photos-dropzone .filepicker .gu-hide {
    display: none !important; }
  .photos-dropzone .filepicker .gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important; }
  .photos-dropzone .filepicker .gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20); }

.tooltip-inner {
  padding: 15px;
  background-color: #5ec7ce;
  color: white;
  opacity: 1 !important;
  border-radius: 10px;
  text-align: inherit;
  white-space: pre-line;
  border: 1px solid transparent; }

.tooltip.top .tooltip-arrow {
  border-top-color: #5ec7ce; }

.tooltip-arrow {
  border-top: 5px solid #5ec7ce; }

.react-switch {
  display: flex !important; }

@-webkit-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px); }
  30%, 70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px); } }

@-moz-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px); }
  30%, 70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px); } }

@keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px); }
  30%, 70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px); } }

@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px); }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); } }

@-moz-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px); }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); } }

@keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px); }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); } }

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }
  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-moz-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }
  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }
  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

.dropzone, .dropzone * {
  box-sizing: border-box; }

.dropzone {
  min-height: 150px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  background: white;
  padding: 20px 20px; }

.dropzone.dz-clickable {
  cursor: pointer; }

.dropzone.dz-clickable * {
  cursor: default; }

.dropzone.dz-clickable .dz-message, .dropzone.dz-clickable .dz-message * {
  cursor: pointer; }

.dropzone.dz-started .dz-message {
  display: none; }

.dropzone.dz-drag-hover {
  border-style: solid; }

.dropzone.dz-drag-hover .dz-message {
  opacity: 0.5; }

.dropzone .dz-message {
  color: #999;
  text-align: center;
  margin: 2em 0; }

.dropzone .dz-preview {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 16px;
  min-height: 100px; }

.dropzone .dz-preview:hover {
  z-index: 1000; }

.dropzone .dz-preview:hover .dz-details {
  opacity: 1; }

.dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 20px;
  background: #999;
  background: linear-gradient(to bottom, #eee, #ddd); }

.dropzone .dz-preview.dz-file-preview .dz-details {
  opacity: 1; }

.dropzone .dz-preview.dz-image-preview {
  background: white; }

.dropzone .dz-preview.dz-image-preview .dz-details {
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear; }

.dropzone .dz-preview .dz-remove {
  font-size: 14px;
  text-align: center;
  display: block;
  cursor: pointer;
  border: none; }

.dropzone .dz-preview .dz-remove:hover {
  text-decoration: underline; }

.dropzone .dz-preview:hover .dz-details {
  opacity: 1; }

.dropzone .dz-preview .dz-details {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 13px;
  min-width: 100%;
  max-width: 100%;
  padding: 2em 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  line-height: 150%; }

.dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 1em;
  font-size: 16px; }

.dz-filename {
  display: none; }

.dropzone .dz-preview .dz-details .dz-filename:hover span {
  border: 1px solid rgba(200, 200, 200, 0.8);
  background-color: rgba(255, 255, 255, 0.8); }

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
  overflow: hidden;
  text-overflow: ellipsis; }

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: 1px solid transparent; }

.dropzone .dz-preview .dz-details .dz-filename span, .dropzone .dz-preview .dz-details .dz-size span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.4em;
  border-radius: 3px; }

.dropzone .dz-preview:hover .dz-image img {
  -webkit-transform: scale(1.05, 1.05);
  -moz-transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  -o-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-filter: blur(8px);
  filter: blur(8px); }

.dropzone .dz-preview .dz-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block; }

.dropzone .dz-preview .dz-image img {
  display: block; }

.dropzone .dz-preview.dz-success .dz-success-mark {
  -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1); }

.dropzone .dz-preview.dz-error .dz-error-mark {
  opacity: 1;
  -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1); }

.dropzone .dz-preview .dz-success-mark, .dropzone .dz-preview .dz-error-mark {
  pointer-events: none;
  opacity: 0;
  z-index: 500;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin-left: -27px;
  margin-top: -27px; }

.dropzone .dz-preview .dz-success-mark svg, .dropzone .dz-preview .dz-error-mark svg {
  display: block;
  width: 54px;
  height: 54px; }

.dropzone .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear; }

.dropzone .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-in;
  -moz-transition: opacity 0.4s ease-in;
  -ms-transition: opacity 0.4s ease-in;
  -o-transition: opacity 0.4s ease-in;
  transition: opacity 0.4s ease-in; }

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse 6s ease infinite;
  -moz-animation: pulse 6s ease infinite;
  -ms-animation: pulse 6s ease infinite;
  -o-animation: pulse 6s ease infinite;
  animation: pulse 6s ease infinite; }

.dropzone .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  height: 16px;
  left: 50%;
  top: 50%;
  margin-top: -8px;
  width: 80px;
  margin-left: -40px;
  background: rgba(255, 255, 255, 0.9);
  -webkit-transform: scale(1);
  transform: scale(1);
  border-radius: 8px;
  overflow: hidden; }

.dropzone .dz-preview .dz-progress .dz-upload {
  background: #333;
  background: linear-gradient(to bottom, #666, #444);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  -webkit-transition: width 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out;
  -ms-transition: width 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out;
  transition: width 300ms ease-in-out; }

.dropzone .dz-preview.dz-error .dz-error-message {
  display: block; }

.dropzone .dz-preview.dz-error:hover .dz-error-message {
  opacity: 1;
  pointer-events: auto; }

.dropzone .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  position: absolute;
  display: block;
  display: none;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  border-radius: 8px;
  font-size: 13px;
  top: 80px;
  left: -10px;
  width: 140px;
  background: #be2626;
  background: linear-gradient(to bottom, #be2626, #a92222);
  padding: 0.5em 1.2em;
  color: white; }

.dropzone .dz-preview .dz-error-message:after {
  content: '';
  position: absolute;
  top: -6px;
  left: 64px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #be2626; }

/* Filepicker CSS */
.filepicker {
  font-family: sans-serif; }

div.filepicker {
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  min-height: 60px;
  border: 2px dashed #C7C7C7; }

/* Icon */
.filepicker-file-icon {
  position: relative;
  display: inline-block;
  margin: 1.5em 0 2.5em 0;
  padding-left: 45px;
  color: black; }

.filepicker-file-icon::before {
  position: absolute;
  top: -7px;
  left: 0;
  width: 29px;
  height: 34px;
  content: '';
  border: solid 2px #7F7F7F;
  border-radius: 2px; }

.filepicker-file-icon::after {
  font-size: 11px;
  line-height: 1.3;
  position: absolute;
  top: 9px;
  left: -4px;
  padding: 0 2px;
  content: 'file';
  content: attr(data-filetype);
  text-align: right;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  background-color: #000; }

.filepicker-file-icon .fileCorner {
  position: absolute;
  top: -7px;
  left: 22px;
  width: 0;
  height: 0;
  border-width: 11px 0 0 11px;
  border-style: solid;
  border-color: white transparent transparent #920035; }

.animated {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-delay: 0.3s; }

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }

.loader {
  border: 16px solid #fff;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite; }

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
